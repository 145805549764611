@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "foundation/_reset";

html,
body {
  font-family: "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 62.5%;
  font-weight: 200;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  background-size: 100% 100%;
  color: #333;
  font-size: 1.6em;
}
